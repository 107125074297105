import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const ifAuthenticated = (to, from, next) => {
  if (!store.getters["user/user"]) {
    next();
    return;
  }
  next({ name: "Home" });
};

const redirectToUserHome = () => {
  const user = store.getters["user/user"];
  if (!user) {
    router.push({ name: "Login" });
  } else {
    if (user.role === "superuser") {
      router.push({ name: "ClientList" });
    } else if (user.role === "warehouseman") {
      router.push({ name: "Warehouse" });
    } else if (["director", "engineer", "performer"].includes(user.role)) {
      router.push({ name: "RequestList" });
    } else {
      router.push({ name: "EquipmentParkList" });
    }
  }
};

const routes = [
  {
    path: "",
    name: "MainLayout",
    redirect: "/",
    component: () => import("@/layouts/MainLayout"),
    children: [
      {
        path: "/",
        name: "Home",
        component: () => import("@/views/Home"),
        meta: {
          auth: true,
          roles: [
            "superuser",
            "admin",
            "operational_duty",
            "cpu",
            "ptd",
            "head",
            "producer",
            "warehouseman",
            "director",
            "engineer",
            "performer",
            "admin_contractor",
          ],
        },
        beforeEnter: redirectToUserHome,
      },
      {
        path: "/login",
        name: "Login",
        component: () => import("@/views/auth/Login"),
        meta: {
          auth: false,
          roles: [],
        },
        beforeEnter: ifAuthenticated,
      },

      {
        path: "/me",
        name: "UserProfile",
        component: () => import("@/views/auth/Profile"),
        meta: {
          auth: true,
          roles: [
            "admin",
            "operational_duty",
            "cpu",
            "ptd",
            "head",
            "producer",
            "director",
            "engineer",
            "admin_contractor",
          ],
        },
      },
      {
        path: "/equipment/catalog",
        name: "EquipmentCatalog",
        component: () => import("@/views/equipment/handbook/Catalog"),
        meta: {
          auth: true,
          roles: ["superuser", "admin", "ptd", "head", "cpu"],
        },
      },
      {
        path: "/equipment/productive-asset",
        name: "EquipmentProductiveAsset",
        component: () => import("@/views/equipment/handbook/ProductiveAsset"),
        meta: {
          auth: true,
          roles: ["superuser", "admin", "engineer"],
        },
      },
      {
        path: "/equipment/handbook",
        name: "EquipmentHandbook",
        component: () => import("@/views/equipment/handbook/Handbook"),
        meta: {
          auth: true,
          roles: ["superuser", "admin", "engineer"],
        },
      },
      {
        path: "/equipment/handbook/type/:typeId/parameter/:parameterType",
        name: "EquipmentHandbookParameter",
        component: () => import("@/views/equipment/handbook/Parameter"),
        meta: {
          auth: true,
          roles: ["superuser", "admin", "engineer"],
        },
      },
      {
        path: "/equipment",
        name: "EquipmentParkList",
        component: () => import("@/views/equipment/park/List"),
        meta: {
          auth: true,
          roles: [
            "admin",
            "operational_duty",
            "cpu",
            "ptd",
            "head",
            "producer",
            "director",
            "engineer",
            "admin_contractor",
            "performer",
          ],
        },
      },
      {
        path: "/equipment/park",
        name: "EquipmentParkTree",
        component: () => import("@/views/equipment/park/Tree"),
        meta: {
          auth: true,
          roles: [
            "admin",
            "operational_duty",
            "cpu",
            "ptd",
            "head",
            "producer",
            "director",
            "engineer",
            "admin_contractor",
            "performer",
          ],
        },
      },
      {
        path: "/equipment/park/:equipmentId",
        name: "EquipmentProfile",
        component: () => import("@/views/equipment/park/Profile"),
        meta: {
          auth: true,
          roles: [
            "admin",
            "operational_duty",
            "cpu",
            "ptd",
            "head",
            "producer",
            "director",
            "engineer",
            "admin_contractor",
          ],
        },
      },
      {
        path: "/company",
        name: "CompanyList",
        component: () => import("@/views/company/List"),
        meta: {
          auth: true,
          roles: ["admin"],
        },
      },
      {
        path: "/department",
        name: "DepartmentList",
        component: () => import("@/views/department/List"),
        meta: {
          auth: true,
          roles: ["admin", "engineer", "admin_contractor"],
        },
      },
      {
        path: "/defect",
        name: "DefectList",
        component: () => import("@/views/defect/List"),
        meta: {
          auth: true,
          roles: [
            "admin",
            "operational_duty",
            "cpu",
            "ptd",
            "head",
            "producer",
          ],
        },
      },
      {
        path: "/defect/:defectId",
        name: "DefectItem",
        component: () => import("@/views/defect/Item"),
        meta: {
          auth: true,
          roles: [
            "admin",
            "operational_duty",
            "cpu",
            "ptd",
            "head",
            "producer",
          ],
        },
      },

      {
        path: "/request",
        name: "RequestList",
        meta: {
          auth: true,
          roles: [
            "admin",
            "director",
            "engineer",
            "performer",
            "admin_contractor",
          ],
        },
        component: () => import("@/views/request/RequestList"),
      },
      {
        path: "/request/:id",
        name: "RequestItem",
        meta: {
          auth: true,
          roles: [
            "admin",
            "director",
            "engineer",
            "performer",
            "admin_contractor",
          ],
        },
        component: () => import("@/views/request/RequestItem"),
      },
      {
        path: "/service-act",
        name: "RequestServiceActList",
        meta: {
          auth: true,
          roles: [
            "admin",
            "director",
            "engineer",
            "performer",
            "admin_contractor",
          ],
        },
        component: () => import("@/views/request/serviceAct/List"),
      },
      {
        path: "/service-act/create",
        name: "RequestServiceActCreate",
        meta: {
          auth: true,
          roles: [
            "admin",
            "director",
            "engineer",
            "performer",
            "admin_contractor",
          ],
        },
        component: () => import("@/views/request/serviceAct/Create"),
      },
      {
        path: "/service-act/:id",
        name: "RequestServiceActItem",
        meta: {
          auth: true,
          roles: [
            "admin",
            "director",
            "engineer",
            "performer",
            "admin_contractor",
          ],
        },
        component: () => import("@/views/request/serviceAct/Item"),
      },
      {
        path: "/service-act/:id/update",
        name: "RequestServiceActUpdate",
        meta: {
          auth: true,
          roles: [
            "admin",
            "director",
            "engineer",
            "performer",
            "admin_contractor",
          ],
        },
        component: () => import("@/views/request/serviceAct/Update"),
      },
      {
        path: "/recommendation",
        name: "RequestRecommendationList",
        component: () => import("@/views/request/RecommendationList"),
        meta: {
          auth: true,
          roles: [
            "admin",
            "director",
            "engineer",
            "performer",
            "admin_contractor",
          ],
        },
      },

      {
        path: "/wpp",
        name: "WPPJournal",
        component: () => import("@/views/wpp/Journal"),
        meta: {
          auth: true,
          roles: [
            "admin",
            "operational_duty",
            "cpu",
            "ptd",
            "head",
            "producer",
          ],
        },
      },
      {
        path: "/wpp/handbook/work",
        name: "WPPHandbookWork",
        component: () => import("@/views/wpp/handbook/Work"),
        meta: {
          auth: true,
          roles: ["admin", "ptd", "head"],
        },
      },
      {
        path: "/wpp/handbook",
        name: "WPPHandbook",
        component: () => import("@/views/wpp/handbook/Handbook"),
        meta: {
          auth: true,
          roles: ["admin", "ptd", "head"],
        },
      },
      {
        path: "/wpp/:equipmentId/calendar",
        name: "WPPEquipmentCalendar",
        component: () => import("@/views/wpp/equipment/Calendar"),
        meta: {
          auth: true,
          roles: [
            "admin",
            "operational_duty",
            "cpu",
            "ptd",
            "head",
            "producer",
          ],
        },
      },

      {
        path: "/wpp_new",
        name: "WPP2Journal",
        component: () => import("@/views/wpp2/List"),
        meta: {
          auth: true,
          roles: [
            "admin",
            "operational_duty",
            "cpu",
            "ptd",
            "head",
            "producer",
            "engineer",
          ],
        },
      },

      {
        path: "/object",
        name: "ObjectList",
        component: () => import("@/views/object/List"),
        meta: {
          auth: true,
          roles: ["admin", "ptd", "head", "engineer", "admin_contractor"],
        },
      },
      {
        path: "/department/:parent",
        name: "DepartmentChildList",
        component: () => import("@/views/department/List"),
        meta: {
          auth: true,
          roles: ["admin", "engineer", "admin_contractor"],
        },
      },

      {
        path: "/staff/position",
        name: "PositionList",
        component: () => import("@/views/staff/handbook/Position"),
        meta: {
          auth: true,
          roles: ["admin", "ptd", "head"],
        },
      },
      {
        path: "/staff/s-and-h-protection",
        name: "StaffSafetyAndHealthProtectionList",
        component: () =>
          import("@/views/staff/handbook/SafetyAndHealthProtection"),
        meta: {
          auth: true,
          roles: ["admin", "ptd", "head"],
        },
      },
      /*{
        path: "/staff/shift",
        name: "ShiftList",
        component: () => import("@/views/staff/handbook/Shift"),
        meta: {
          auth: true,
          roles: ["admin", "ptd", "head"],
        },
      },*/
      {
        path: "/staff",
        name: "StaffList",
        component: () => import("@/views/staff/List"),
        meta: {
          auth: true,
          roles: ["admin", "ptd", "head", "engineer", "admin_contractor"],
        },
      },
      {
        path: "/staff/schedule/shift-work",
        name: "StaffShiftWorkSchedule",
        component: () => import("@/views/staff/handbook/schedule/ShiftWork"),
        meta: {
          auth: true,
          roles: ["admin", "ptd", "head"],
        },
      },
      /*{
        path: "/staff/schedule/general",
        name: "StaffGeneralSchedule",
        component: () => import("@/views/staff/handbook/schedule/General"),
        meta: {
          auth: true,
          roles: ["admin", "ptd", "head"],
        },
      },*/
      {
        path: "/staff/work-schedule",
        name: "WorkSchedule",
        component: () => import("@/views/staff/handbook/workSchedule/List"),
        meta: {
          auth: true,
          roles: ["admin", "head"],
        },
      },
      {
        path: "/staff/timetable",
        name: "TimeTable",
        component: () => import("@/views/staff/handbook/TimeTable"),
        meta: {
          auth: true,
          roles: ["admin", "head"],
        },
      },
      {
        path: "/staff/:userId",
        name: "StaffItem",
        component: () => import("@/views/staff/Item"),
        meta: {
          auth: true,
          roles: ["admin", "ptd", "head", "engineer", "admin_contractor"],
        },
      },

      {
        path: "/client",
        name: "ClientList",
        component: () => import("@/views/client/List"),
        meta: {
          auth: true,
          roles: ["superuser"],
        },
      },
      {
        path: "/regulation/form",
        name: "RegulationForm",
        component: () => import("@/views/regulation/Form"),
        meta: {
          auth: true,
          roles: ["admin", "ptd", "head", "cpu", "engineer"],
        },
      },
      {
        path: "/regulation",
        name: "RegulationList",
        component: () => import("@/views/regulation/Regulation"),
        meta: {
          auth: true,
          roles: ["admin", "ptd", "head", "cpu", "engineer"],
        },
      },
      {
        path: "/regulation/schedule",
        name: "RegulationSchedule",
        component: () => import("@/views/regulation/Schedule"),
        meta: {
          auth: true,
          roles: ["admin", "ptd", "head", "cpu", "engineer"],
        },
      },
      {
        path: "/regulation/history",
        name: "RegulationHistoryList",
        component: () => import("@/views/regulation/History"),
        meta: {
          auth: true,
          roles: [
            "admin",
            "ptd",
            "head",
            "operational_duty",
            "cpu",
            "engineer",
          ],
        },
      },
      {
        path: "/regulation/history/:historyId",
        name: "RegulationHistoryItem",
        component: () => import("@/views/regulation/HistoryItem"),
        meta: {
          auth: true,
          roles: [
            "admin",
            "ptd",
            "head",
            "operational_duty",
            "cpu",
            "engineer",
          ],
        },
      },

      {
        path: "/work-order",
        name: "WorkOrderJournal",
        component: () => import("@/views/workOrder/Journal"),
        meta: {
          auth: true,
          roles: ["admin", "ptd", "head"],
        },
      },
      {
        path: "/work-order/:equipmentId/calendar",
        name: "WorkOrderEquipmentCalendar",
        component: () => import("@/views/workOrder/equipment/Calendar"),
        meta: {
          auth: true,
          roles: ["admin", "ptd", "head"],
        },
      },
      {
        path: "/work-order/:workOrderId",
        name: "WorkOrderItem",
        component: () => import("@/views/workOrder/Item"),
        meta: {
          auth: true,
          roles: ["admin", "ptd", "head"],
        },
      },
      {
        path: "/work-order/protection-mean",
        name: "WorkOrderProtectionMeanList",
        component: () => import("@/views/workOrder/handbook/ProtectionMean"),
        meta: {
          auth: true,
          roles: ["admin", "ptd", "head"],
        },
      },
      {
        path: "/work-order/security-measure",
        name: "WorkOrderSecurityMeasureList",
        component: () => import("@/views/workOrder/handbook/SecurityMeasure"),
        meta: {
          auth: true,
          roles: ["admin", "ptd", "head"],
        },
      },
      {
        path: "/work-order/customer",
        name: "WorkOrderCustomerList",
        component: () => import("@/views/workOrder/handbook/Customer"),
        meta: {
          auth: true,
          roles: ["admin", "ptd", "head"],
        },
      },

      {
        path: "/work_order",
        name: "WorkOrder2List",
        component: () => import("@/views/workOrder2/List"),
        meta: {
          auth: true,
          roles: ["admin", "ptd", "head"],
        },
      },
      {
        path: "/work_order/:id",
        name: "WorkOrder2Item",
        component: () => import("@/views/workOrder2/Item"),
        meta: {
          auth: true,
          roles: ["admin", "ptd", "head"],
        },
      },

      {
        path: "/aggregate-journal",
        name: "AggregateJournalList",
        component: () => import("@/views/aggregateJournal/List"),
        meta: {
          auth: true,
          roles: ["admin", "ptd", "head"],
        },
      },

      {
        path: "/report/work-order",
        name: "ReportWorkOrder",
        component: () => import("@/views/report/WorkOrder"),
        meta: {
          auth: true,
          roles: ["admin", "cpu", "engineer"],
        },
      },
      {
        path: "/report/statement/",
        name: "ReportStatementList",
        component: () => import("@/views/report/statement/StatementList"),
        meta: {
          auth: true,
          roles: ["admin", "cpu", "engineer"],
        },
      },
      {
        path: "/report/statement/:id",
        name: "StatementItem",
        component: () => import("@/views/report/statement/StatementItem"),
        meta: {
          auth: true,
          roles: ["admin", "cpu", "engineer"],
        },
      },
      {
        path: "/report/",
        name: "ReportList",
        component: () => import("@/views/report/report/ReportList"),
        meta: {
          auth: true,
          roles: ["admin", "cpu", "engineer"],
        },
      },
      {
        path: "/report/:id",
        name: "ReportItem",
        component: () => import("@/views/report/report/ReportItem"),
        meta: {
          auth: true,
          roles: ["admin", "cpu", "engineer"],
        },
      },
      {
        path: "/report/bvx/",
        name: "ReportBVX",
        component: () => import("@/views/report/BVX"),
        meta: {
          auth: true,
          roles: ["admin", "cpu", "engineer"],
        },
      },

      {
        path: "/analytics",
        name: "AnalyticsOverall",
        component: () => import("@/views/analytics/Analytics"),
        meta: {
          auth: true,
          roles: ["admin", "ptd", "head", "cpu", "engineer"],
        },
      },
      {
        path: "/analytics/defect",
        name: "AnalyticsDefectOverall",
        component: () => import("@/views/analytics/defect/Overall"),
        meta: {
          auth: true,
          roles: ["admin", "head", "ptd", "engineer"],
        },
      },
      {
        path: "/analytics/wpp",
        name: "AnalyticsWPPOverall",
        component: () => import("@/views/analytics/wpp/Overall"),
        meta: {
          auth: true,
          roles: ["admin", "head", "ptd", "engineer"],
        },
      },
      {
        path: "/analytics/equipment-condition",
        name: "AnalyticsEquipmentCondition",
        component: () => import("@/views/analytics/EquipmentCondition"),
        meta: {
          auth: true,
          roles: ["admin", "ptd", "head", "engineer"],
        },
      },

      {
        path: "/monitoring",
        name: "MonitoringJournal",
        component: () => import("@/views/monitoring/Journal"),
        meta: {
          auth: true,
          roles: ["admin", "ptd", "head"],
        },
      },
      {
        path: "/monitoring/:equipmentId/calendar",
        name: "MonitoringEquipmentCalendar",
        component: () => import("@/views/monitoring/equipment/Calendar"),
        meta: {
          auth: true,
          roles: ["admin", "ptd", "head"],
        },
      },

      {
        path: "/warehouse",
        name: "Warehouse",
        component: () => import("@/views/warehouse/List"),
        meta: {
          auth: true,
          roles: ["admin", "ptd", "head", "warehouseman"],
        },
      },

      {
        path: "/budget",
        name: "BudgetList",
        component: () => import("@/views/budget/List"),
        meta: {
          auth: true,
          roles: ["*"],
        },
      },
      {
        path: "/budget/:id",
        name: "BudgetDetail",
        component: () => import("@/views/budget/Item"),
        meta: {
          auth: true,
          roles: ["*"],
        },
      },

      {
        path: "/inventory",
        name: "InventoryList",
        component: () => import("@/views/inventory/List"),
        meta: {
          auth: true,
          roles: ["*"],
        },
      },

      {
        path: "/safety/document",
        name: "SafetyDocumentList",
        component: () => import("@/views/safety/handbook/Document"),
        meta: {
          auth: true,
          roles: ["admin", "ptd", "head"],
        },
      },
      {
        path: "/safety/violation",
        name: "SafetyViolationList",
        component: () => import("@/views/safety/violation/List"),
        meta: {
          auth: true,
          roles: ["admin", "ptd", "head"],
        },
      },
      {
        path: "/safety/s-and-h-protection",
        name: "SafetyAndHealthProtectionList",
        component: () =>
          import("@/views/safety/handbook/SafetyAndHealthProtection"),
        meta: {
          auth: true,
          roles: ["admin", "ptd", "head", "manager_safety"],
        },
      },
      {
        path: "/safety/s-and-h-protection-validity",
        name: "SafetyAndHealthProtectionValidity",
        component: () =>
          import("@/views/safety/safetyAndHealthProtection/Validity"),
        meta: {
          auth: true,
          roles: ["admin", "ptd", "head", "manager_safety"],
        },
      },

      {
        path: "/ptd",
        name: "PTDList",
        component: () => import("@/views/ptd/List"),
        meta: {
          auth: true,
          roles: ["admin", "ptd", "cpu"],
        },
        redirect: { name: "PTDProductionPlan" },
        children: [
          {
            path: "production-plan",
            name: "PTDProductionPlan",
            component: () => import("@/views/ptd/productionPlan/List"),
          },
          {
            path: "daily-chart",
            name: "PTDDailyChart",
            component: () => import("@/views/ptd/dailyChart/List"),
          },
          {
            path: "daily-chart/:id",
            name: "PTDDailyChartItem",
            component: () => import("@/views/ptd/dailyChart/Show"),
          },
          {
            path: "production-daily",
            name: "PTDProductionDaily",
            component: () => import("@/views/ptd/productionDaily/List"),
          },
          {
            path: "askue",
            name: "PTDAskue",
            component: () => import("@/views/ptd/askue/List"),
          },
        ],
      },
      {
        path: "/ptd/shpp",
        name: "PTDSHPPList",
        component: () => import("@/views/ptd/shpp/List"),
        meta: {
          auth: true,
          roles: ["admin", "ptd", "cpu"],
        },
      },
      {
        path: "/ptd/shpp/:id",
        name: "PTDSHPPItem",
        component: () => import("@/views/ptd/shpp/Item"),
        meta: {
          auth: true,
          roles: ["admin", "ptd", "cpu"],
        },
      },
      {
        path: "/ptd/production-plan/:id",
        name: "PTDProductionPlanShow",
        component: () => import("@/views/ptd/productionPlan/Show"),
        meta: {
          auth: true,
          roles: ["admin", "ptd", "cpu"],
        },
      },
      {
        path: "/ptd/statement-depreciation",
        name: "PTDStatementDepreciation",
        component: () => import("@/views/ptd/statementDepreciation/List"),
        meta: {
          auth: true,
          roles: ["admin", "ptd", "cpu", "engineer"],
        },
      },

      {
        path: "/handbook/work-type",
        name: "HandbookPPRWorkType",
        meta: { requiresAuth: true },
        component: () => import("@/views/handbook/ppr/WorkType.vue"),
      },
      {
        path: "/handbook/position",
        name: "HandbookPPRPosition",
        meta: { requiresAuth: true },
        component: () => import("@/views/handbook/ppr/Position.vue"),
      },
      {
        path: "/handbook/rank",
        name: "HandbookPPRRank",
        meta: { requiresAuth: true },
        component: () => import("@/views/handbook/ppr/Rank.vue"),
      },
      {
        path: "/handbook/technical-place",
        name: "HandbookTechnicalPlace",
        meta: { requiresAuth: true },
        component: () => import("@/views/handbook/TechnicalPlace.vue"),
      },

      {
        path: "*",
        name: "PageNotFound",
        component: () => import("@/views/PageNotFound"),
        meta: {
          auth: false,
          roles: [],
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.auth) {
    const user = store.getters["user/user"];
    if (user) {
      let role = user.role;
      if (user.company_info && user.company_info.role === "contractor") {
        role = "admin_contractor";
      }
      if (to.meta.roles.includes(role) || to.meta.roles.includes("*")) {
        next();
      } else {
        next({ name: "PageNotFound" });
      }
    } else {
      store
        .dispatch("user/logoutUser", {}, { root: true })
        .then(() => next({ name: "Login" }));
    }
  } else {
    next();
  }
});

export default router;
